<template>
  <div class="w-full flex items-center gap-x-2 py-2">
    <CarouselElement
      :items="channels"
      :enable-nav="false"
      :override-options="carouselOptions"
      element-id="channelListCarousel"
      class="w-[80vw]"
    >
      <template #slider-item="{ item, key }">
        <button @click="onChannelClick(item)">
          <div
            :class="[isSelected(item) ? 'bg-white/30' : 'bg-white/10']"
            class="flex-shrink-0 rounded-[4px] w-20 md:w-28 aspect-video"
          >
            <div
              :style="{ backgroundImage: `url('${item.images.logo}')` }"
              class="h-full w-full rounded-[4px] object-contain bg-center bg-contain bg-no-repeat"
            ></div>
          </div>
        </button>
      </template>
    </CarouselElement>
    <slot name="epg"></slot>
  </div>
</template>

<script setup>
const props = defineProps({
  channels: {
    type: Array,
    required: true,
  },
  path: String,
  carouselOptions: {
    type: Object,
    default: () => null,
  },
  epgPath: {
    type: String,
    required: true,
  },
  emitChannelChange: {
    type: Boolean,
    default: () => false,
  },
});

const emit = defineEmits("onChannelChange");

const route = useRoute();
const router = useRouter();

const { isXs } = useBreakpoints();

const localePath = useLocalePath();

const isSelected = (channel) => {
  const externalChannelId = route.query.station;
  return externalChannelId === channel.externalChannelId;
};

const onChannelClick = (channel) => {
  if (props.emitChannelChange) {
    emit("onChannelChange", channel);
    return;
  }
  router.replace({
    path: localePath(props.path || route.path),
    query: {
      station: channel.externalChannelId,
    },
  });
};
</script>

<style lang="scss" scoped></style>
